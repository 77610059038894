<template>
  <section>
    <v-container class="contain">
      <v-row>
        <v-col>
          <div v-if="!loadingTable">
            <Loading />
          </div>

          <div v-if="loadingTable">
            <h1 class="ml-3">Total Properties By Region</h1>
            <div class="dashboardCard">
              <div
                v-for="(data, index) in postcodereports.regionReport"
                class="cardIcon"
                :class="{ activeCard: region == data.region }"
                :key="index"
                @click="fiterRegion(data.region)"
              >
                <h1>{{ data.region }}</h1>
                <strong>{{ data.count }}</strong>
              </div>
            </div>
          </div>

          <div class="tablePostcode" v-if="loadingTable">
            <h1 class="px-4 py-2 d-flex align-center justify-space-between">
              Dataset of {{ region }} ({{
                allpostcodCountReport.results.createdAt
                  | moment("MMMM Do YYYY : hh :mm A")
              }})
              <div class="searchBox">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search Postcode"
                  single-line
                  hide-details
                  outlined
                  dense
                ></v-text-field>
              </div>
            </h1>

            <v-data-table
              :headers="headers"
              :items="postcodereports.reports"
              :items-per-page="100"
              :search="search"
              class="elevation-1"
              :hide-default-footer="true"
            >
              <v-spacer></v-spacer>
            </v-data-table>
          </div>
          <div class="tablePostcode" v-if="loadingTable">
            <h1 class="px-4 py-2">
              Machine Learning Complete Status Up Until (
              {{
                allpostcodCountReport.results.createdAt
                  | moment("MMMM Do YYYY : hh :mm A")
              }})
            </h1>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Sold STC Status</th>
                    <th class="text-left">Auction Status</th>
                    <th class="text-left">Park Home Status</th>
                    <th class="text-left">Shared Ownership Status</th>
                    <th class="text-left">Retirement Status</th>
                    <th class="text-left">Refurbishment Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <v-icon color="green" class="mr-1">
                        mdi-check-circle
                      </v-icon>
                      Complete Successfully
                    </td>
                    <td>
                      <v-icon color="green" class="mr-1">
                        mdi-check-circle
                      </v-icon>
                      Complete Successfully
                    </td>
                    <td>
                      <v-icon color="green" class="mr-1">
                        mdi-check-circle
                      </v-icon>
                      Complete Successfully
                    </td>
                    <td>
                      <v-icon color="green" class="mr-1">
                        mdi-check-circle
                      </v-icon>
                      Complete Successfully
                    </td>
                    <td>
                      <v-icon color="green" class="mr-1">
                        mdi-check-circle
                      </v-icon>
                      Complete Successfully
                    </td>
                    <td>
                      <v-icon color="green" class="mr-1">
                        mdi-check-circle
                      </v-icon>
                      Complete Successfully
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>
          <div class="tablePostcode" v-if="loadingTable">
            <h1 class="px-4 py-2">HM Land Registry data</h1>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">File Name</th>
                    <th class="text-left">Date</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>pp-monthly-update-new-version.csv</td>
                    <td>August 2022</td>
                  </tr>
                  <tr>
                    <td>pp-complete.csv</td>
                    <td>August 2022-1995</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Loading from "@/components/dashboard/Loading";
export default {
  name: "Home",
  data: () => ({
    loading: false,
    loadingTable: false,
    valueated: true,
    value: 0,
    submitLoad: false,
    postcodereports: undefined,
    headers: [
      { text: "Postcode", value: "postcode" },
      { text: "Rightmove Count", value: "rmcount" },
      { text: "TVA Count", value: "tva" },
      { text: "Difference", value: "difference" },
    ],
    search: undefined,
    region: undefined,
  }),
  components: {
    Loading,
  },
  methods: {
    ...mapActions([
      "getAllPropertyDetails",
      "dashboardControl",
      "getDashboradControl",
      "postcodeCountReport",
    ]),
    async fiterRegion(region) {
      this.region = region;
      await this.postcodeCountReport();
      this.postcodereports = this.allpostcodCountReport.results;
      this.postcodereports.reports =
        this.allpostcodCountReport.results.reports.filter((ele) => {
          return region == ele.region;
        });
    },
    valuatedAction(data) {
      this.valueated = data;
    },
    async validate(data) {
      this.submitLoad = true;
      await this.dashboardControl({ title: data, value: this.value });
      this.submitLoad = false;
    },
  },
  computed: {
    ...mapGetters([
      "allPropertyDetails",
      "allDashboardControl",
      "allpostcodCountReport",
    ]),
  },
  async created() {
    await this.getDashboradControl({ title: "yieldRequired" });
    this.value = this.allDashboardControl.results.value;
    await this.fiterRegion("North East");
    this.loadingTable = true;
    this.loading = true;
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
.cardIcon {
  cursor: pointer;
}
.dashboardCard {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  div {
    width: 23%;
    border-radius: 10px;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    height: 120px;
    box-shadow: 0 3px 10px #e9e8e8;
    margin-bottom: 20px;
    margin: 10px;
    h1 {
      color: #0b5e8b;
    }
    strong {
      font-size: 35px;
      color: $menucolor;
    }
  }
}
.filterDropdown {
  display: inline-block;
  text-align: left;
  position: relative;
  &:hover {
    ul {
      display: block;
    }
  }
  div {
    width: 250px;
    border: 1px solid #efefef;
    padding: 10px;
  }
  .v-icon {
    font-size: 18px;
  }
  ul {
    position: absolute;
    top: 100%;
    width: 250px;
    list-style: none;
    padding-left: 0;
    z-index: 999;
    display: none;
    li {
      padding: 10px 15px;
      box-shadow: 0 2px 3px #efefef;
      background: #fff;
      cursor: pointer;
    }
    margin-bottom: 0;
  }
}
.tablePostcode {
  border: 1px solid #c8c3c3;
  padding: 10px;
  margin: 15px;
  border-radius: 10px;
  h1 {
    font-size: 20px;
  }
  th {
    font-size: 16px !important;
  }
}
.searchBox {
  width: 20%;
}
.activeCard {
  background: #2583c5;
  strong {
    color: #fff !important;
  }
  h1 {
    color: #fff !important;
  }
}
</style>