<template>
  <section>
    <v-row>
      <v-col md="12" v-if="loading"> <Loading /> </v-col>
      <v-col md="12" v-if="!loading">
        <h3 class="mb-3">Registerd User List</h3>
        <v-simple-table class="tableborder">
          <thead>
            <tr>
              <th>S/N</th>
              <th>Name</th>
              <th>Userhandle</th>
              <th>email</th>
              <th>Verified</th>
              <th>Status</th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="(data, index) in allUser.users" :key="index">
              <td>{{ index + 1 }}</td>
              <td>{{ data.fullname }}</td>
              <td>{{ data.userHandle }}</td>
              <td>{{ data.email }}</td>
              <td>
                <v-switch
                  v-model="data.verified"
                @change="updateUser(data._id)"
                ></v-switch>
              </td>
              <td>{{ data.status ? "Yes" : "No" }}</td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Loading from "@/components/dashboard/Loading";
export default {
  name: "Users",
  components: {
    Loading,
  },
  computed: {
    ...mapGetters(["allUser"]),
  },
  data() {
    return {
      skip: 1,
      limit: 100,
      loading: true,
    };
  },
  methods: {
    ...mapActions(["getUsers","putUsers"]),
    async updateUser(data){
      await this.putUsers(data);
    }
  },
  async created() {
    await this.getUsers();
    this.loading = false;
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
.primary {
  background: $menucolor !important;
}
.danger {
  background: rgb(218, 2, 2) !important;
}
.sub-menus {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 20px;
  padding-left: 0;
  li {
    a {
      display: block;
      padding: 10px 20px;
      text-decoration: none;
      box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
      margin-right: 10px;
      color: $dark;
      background: $white;
      border-radius: 5px;
      // color: $white;
    }
  }
}
</style>